* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #000000;
  color: #ffffff;
}

a {
  color: #f39200;
}

a:hover {
  color: #f39200;
}

.wrapper {
  width: 100%;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

header .wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  height: 130px;
  padding: 0 2.5rem;
}

header img {
  width: 175px;
}

header nav ul {
  list-style-type: none;
  display: flex;
  gap: 2rem;
}

header nav ul li a {
  color: #ffffff;
}

header nav ul li a:hover {
  color: #ffffff;
}

.info {
  color: #f30000;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  padding-top: 250px;
}

h1 {
  padding: 15px;
  font-size: 75px;
  text-shadow: 2px 3px white;
}

.btn-warning {
  margin-top: 15px;
  padding: 10px 25px;
  border-radius: 0 !important;
  border: none;
}

#formm {
  padding-top: 150px;
}

.login,
.home,
.register,
.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.login form,
.register form {
  max-width: 300px;
  width: 100%;
}

.login form div,
.register form div {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}

.login form div input,
.register form div input {
  width: 100%;
  background-color: #fff;
  color: #777;
  border-width: 1px;
  border-color: #cccccc;
  height: 50px;
  flex-grow: 1;
  min-width: 1px;
  margin: 0;
  position: relative;
  z-index: 2;
  margin-bottom: 0;
  height: 50px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 3.428571;
  background-image: none;
  border: 1px solid #ccc;
  border-style: solid;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  outline: 0;
}

.login form button,
.register form button {
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0.75rem;
  background-color: #f39200;
  border: none;
  color: #ffffff;
}

.imageUpload form label {
  position: relative;
  cursor: pointer;
}

.imageUpload form label img {
  border-top-left-radius: 1.5rem;
}

.imageUpload form label:hover:after {
  opacity: 1;
}

.imageUpload form label:after {
  content: 'Change Image';
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.imageUpload form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.codeSearch {
  max-width: 350px;
  padding: 0.5rem 1.5rem;
  width: 100%;
}

.imageUpload form button {
  padding: 0.5rem 1.5rem;
  background-color: #f39200;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  border-radius: 0.25rem;
}

.imageUpload form button:hover {
  background-color: #d07d00;
}

.config-container .batterie-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 1rem;
}

.config-container .batterie-body .box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0;
  gap: 1rem;
  flex-grow: 1;
  width: calc(100% * (1/2) - 10px - 1px);
}

.config-container .batterie-body fieldset {
  border: 1px solid #777;
  padding: 1.5rem;
  flex-grow: 1;
}

.config-container .batterie-body fieldset form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.config-container .batterie-body fieldset form button {
  background-color: #f39200;
  padding: 0.5rem 1.5rem;
  color: #ffffff;
  border: none;
  align-self: center;
}

.config-container .batterie-body fieldset form button:hover {
  background-color: #d07d00;
}

.config-container .batterie-body fieldset legend {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.5rem;
}

.config-container .batterie-body fieldset legend a {
  color: #f39200;
}

.config-container .batterie-body fieldset .tripple-input {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  flex-grow: 1;
}

.config-container .batterie-body fieldset .tripple-input .input-group {
  width: fit-content;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 30%;
}

.config-container .batterie-body fieldset .tripple-input label {
  font-size: 10px;
}