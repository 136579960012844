.home {
  padding: 20px;
  margin-top: 10rem;
  justify-content: flex-start !important;
}

.collectors {
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
  width: 100%;
  box-sizing: border-box;
  /* Fügt Padding und Border zur Gesamtbreite hinzu */
}

.collector {
  flex: 1;
  /* Erlaubt, dass das Element gleichmäßig wächst */
}

.collapsible {
  background-color: #f39200;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  text-align: left;
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  box-sizing: border-box;
  /* Fügt Padding zur Gesamtbreite hinzu */
}

.collapsible:hover {
  background-color: #d07d00;
}

.content {
  display: none;
  overflow: hidden;
  padding: 1.5rem;
  border: 1px solid #ddd;
  border-top: none;
  flex-direction: column;
  /* Verwende Column für die Batteries */
  box-sizing: border-box;
  /* Fügt Padding und Border zur Gesamtbreite hinzu */
}

.content.active {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* Abstand zwischen den Listenelementen */
}

.battery {
  margin-bottom: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.progress {
  position: relative;
  width: 180px;
  text-align: center;
  height: 90px;
  background: none;
}

.barOverflow {
  /* Wraps the rotating .bar */
  position: relative;
  overflow: hidden;
  /* Comment this line to understand the trick */
  width: 180px;
  height: 90px;
  /* Half circle (overflow) */
  margin-bottom: -14px;
  /* bring the numbers up */
}

.bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 180px;
  height: 180px;
  /* full circle! */
  border-radius: 50%;
  box-sizing: border-box;
  border: 15px solid #3e3e3e;
  /* half gray, */
  border-bottom-color: #f39200;
  /* half azure */
  border-right-color: #f39200;
}

.bar-percent-text {
  position: absolute;
  width: 100%;
  bottom: 1rem;
}

.bar-text {
  font-weight: 600;
  font-size: 20px;
}

.batterie-headline {
  width: 100%;
  padding: 1rem 1.5rem;
  background-color: #232323;
  border-radius: 5px;
}

.batterie-body {
  background-color: #323232;
  width: 100%;
  padding: 1rem 1.5rem;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.batterie-body .card {
  width: 230px;
  height: 150px;
  display: flex;
  flex-direction: column;
  background-color: #282828;
}

.batterie-body .card .card-headline {
  color: #AAAAAA;
  font-size: 14px;
  font-weight: bold;
  padding: 0.25rem;
}

.batterie-body .card .card-body {
  color: #F39200;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: inherit;
}

.batterie-body .card .text-small {
  font-size: 14px;
}

.batterie-body .card .card-link {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: inherit;
  cursor: pointer;
  pointer-events: auto;
}

.batterie-body .card .card-link .card-body {
  color: #7F7F7F;
  font-weight: normal;
}

.batterie-body .card .card-link:hover {
  background-color: #5f5f5f;
}

/* CSS für vertikale Fortschrittsanzeige */
.vertical-progress {
  width: 50px;
  /* Breite der Fortschrittsanzeige */
  height: 100px;
  /* Höhe der Fortschrittsanzeige */
  border: 2px solid #ccc;
  /* Rahmen der Fortschrittsanzeige */
  border-radius: 5px;
  /* Abgerundete Ecken */
  position: relative;
  /* Für die absolute Positionierung der Fortschrittsbalken */
}

.capacity-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.capacity-value {
  color: #5f5f5f;
  font-size: 14px;
  position: absolute;
  right: 0;
}

.progress-bar {
  width: 100%;
  height: 100%;
  background-color: #f39200;
  /* Hintergrundfarbe des Fortschrittsbalkens */
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 5px;
}

.progress-text {
  position: absolute;
  font-size: 14px;
  right: 0;
}

.report-container {
  width: 80%;
}